import React from 'react'
import { Main } from 'layouts'
import { PersonalSleepConnected } from 'components/Phantom/_sections/PersonalSleep'
import { SlideOutBenefits } from 'components/Phantom/_sections/SlideOutBenefits'
import { WhoWeAre } from 'components/Phantom/_sections/WhoWeAre'
import { FeaturedReviews } from 'components/Phantom/_sections/FeaturedReviews'
import { ScrollSequenceStatic } from 'components/Phantom/_sections/ScrollSequenceStatic'
import { Pod4HeroHomepage } from 'components/Phantom/_sections/Pod4Hero'
import { IntelligentSystem } from 'components/Phantom/_sections/IntelligentSystem'
import { NewStandardConnected } from 'components/Phantom/_sections/NewStandard'
import { NoPhoneConnected } from 'components/Pod4NoPhone'
import { IndustryLeaders } from 'components/Phantom/_sections/IndustryLeaders'
import { AutopilotBrieferCTAStoreConnected } from 'components/Phantom/_sections/AutopilotBriefer'
import { ClientOnly } from 'components/ClientOnly'

export const Home2024 = () => {
	const anchorId = 'inteligent-system'
	return (
		<Main inverted>
			<Pod4HeroHomepage id={anchorId} />
			<ClientOnly>
				<IntelligentSystem id={anchorId} />
				<NewStandardConnected />
				<FeaturedReviews />
				<PersonalSleepConnected />
				<AutopilotBrieferCTAStoreConnected />
				<NoPhoneConnected />
				<SlideOutBenefits />
				<IndustryLeaders />
				<WhoWeAre />
				<ScrollSequenceStatic />
			</ClientOnly>
		</Main>
	)
}
